import Linkify from 'linkify-react';

export const baseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_BASE_URL
  : 'https://chat.telein.com.br/api';

export const webhookBaseUrl = process.env.NODE_ENV === 'development'
  ? process.env.REACT_APP_SERVER_WEBHOOK_URL
  : 'https://chat.telein.com.br/webhook';

export const webhookTeleinUrl = 'https://chat.telein.com.br/webhookTeleinIA';

export const baseMediaUrl = 'https://chat.telein.com.br/proxy/panfletointeligente/webhook/assinar_s3.php?mediaUrl=';

export const deleteMessageUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/deleteMensagem.php';

const sendMessageUrl = 'https://chat.telein.com.br/proxy/apis/enviomassa/enviarMensagem.php';

export const postRequest = async (url, body) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body,
  });

  const data = await response.json();

  if (!response.ok) {
    let message;

    if (data?.message) {
      message = data.message;
    } else {
      message = data;
    }

    return { error: true, message };
  }

  return data;
};

export const sendMessageRequest = async (token, message, phoneNumber, mediaUrl) => {
  const body = {
    token,
    mensagem: message,
    telefone: phoneNumber,
    ...(mediaUrl && { url: mediaUrl }),
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    redirect: 'follow',
  };

  try {
    const response = await fetch(sendMessageUrl, requestOptions);
    const data = await response.json();

    if (!response.ok) {
      const message = data?.message || data;
      return { error: true, message };
    }

    return data;
  } catch (error) {
    return { error: true, message: error.message };
  }
};

export const postFormDataRequest = async (url, formData) => {
  const response = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  const responseData = await response.json();

  if (!response.ok) {
    let message;

    if (responseData?.message) {
      message = responseData.message;
    } else {
      message = responseData;
    }

    return { error: true, message };
  }

  return responseData;
};

export const getRequest = async (url) => {
  const response = await fetch(url);

  const data = await response.json();

  if (!response.ok) {
    let message = 'An error occured!';

    if (data?.message) {
      message = data.message;
    }

    return { error: true, message, statusCode: response.status };
  }

  return data;
};

export const patchRequest = async (url, body) => {
  try {
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    if (!response.ok) {
      throw new Error(`Error making PATCH request: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    throw new Error('Error making PATCH request:', error);
  }
};

export const deleteRequest = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error making DELETE request: ${response.statusText}`);
    }

    if (response.status === 204) {
      return null;
    }

    return await response.json();
  } catch (error) {
    throw new Error(`Error making DELETE request: ${error.message}`);
  }
};

export const formatPhoneNumber = (phone) => {
  if (!phone) return;

  const cleanPhone = phone.split('@')[0];

  const hasPlus = cleanPhone.startsWith('+');
  const normalizedPhone = hasPlus ? cleanPhone.slice(1) : cleanPhone;

  const countryCode = normalizedPhone.slice(0, 2);
  const areaCode = normalizedPhone.slice(2, 4);
  const numberPart1 = normalizedPhone.slice(4, 8);
  const numberPart2 = normalizedPhone.slice(8);

  return `${hasPlus ? '+' : ''}${countryCode} (${areaCode}) ${numberPart1}-${numberPart2}`;
};

export const fixEncodingStrings = (str) => {
  const isMalformed = /�|Ã|&[a-z]+;/.test(str);

  if (isMalformed) {
    const bytes = new Uint8Array([...str].map((char) => char.charCodeAt(0)));
    const decoder = new TextDecoder('utf-8');
    return decoder.decode(bytes);
  }

  return str;
};

export const removeAfterSecondAmpersand = (str) => {
  const firstAmpersandIndex = str.indexOf('&');
  const secondAmpersandIndex = str.indexOf('&', firstAmpersandIndex + 1);

  if (firstAmpersandIndex !== -1 && secondAmpersandIndex !== -1) {
    return str.slice(0, secondAmpersandIndex);
  }

  return str;
};

export const getSenderName = (fromMe, currentChat) => {
  if (fromMe === 0) {
    return currentChat.customName
    || currentChat.nome
    || formatPhoneNumber(currentChat.fromMsg);
  }
};

export const formatContactCardBody = (body, mediaType) => {
  if (mediaType === 'contactMessage') {
    const nameMatch = body.match(/FN:([^;]*);/);
    const contactName = nameMatch ? nameMatch[1].trim() : '';
    const phoneMatches = [...body.matchAll(/waid=(\d+):/g)];

    return (
      <>
        <h1>{contactName}</h1>
        {phoneMatches.map((match, index) => (
          <span key={index}>
            {formatPhoneNumber(match[1])}
          </span>
        ))}
      </>
    );
  }
  return null;
};

export const formatMessageBody = (message, isDeleted = null, isInternalChat) => {
  const regex = /^\*(.*?)\*:\s*/;
  const match = message.match(regex);

  const linkifyOptions = {
    target: '_blank',
    rel: 'noopener noreferrer',
    className: 'custom-linkify-link',
  };

  const formatTextWithLineBreaks = (text) => {
    return text.split('\n').map((line, index) => (
      <>
        {line}
        <br key={index} />
      </>
    ));
  };

  const messageStyle = {
    textDecoration: isDeleted
      ? 'line-through'
      : 'none',
  };

  if (match) {
    const boldText = match[1];
    const remainingText = message.replace(regex, '');

    return (
      <>
        <i><strong>{`${boldText}:`}</strong></i>
        {((!isDeleted && !isInternalChat)
        || (isInternalChat && !isDeleted)
        || !isInternalChat) && (
          <p style={messageStyle}>
            <Linkify options={linkifyOptions}>
              {formatTextWithLineBreaks(remainingText)}
            </Linkify>
          </p>
        )}

      </>
    );
  }

  return (
    <p style={messageStyle}>
      <Linkify options={linkifyOptions}>
        {formatTextWithLineBreaks(message)}
      </Linkify>
    </p>
  );
};

export const stripPrefix = (idWithPrefix) => {
  const separatorIndex = idWithPrefix.indexOf('-');
  if (separatorIndex !== -1) {
    return idWithPrefix.slice(separatorIndex + 1);
  }
  return idWithPrefix;
};

export const ItemTypes = {
  CARD: 'CARD',
  CHAT: 'CHAT',
};
