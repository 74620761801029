import React, { useState, useContext } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  Badge,
} from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import utc from 'dayjs/plugin/utc';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ContainerSchedule } from './styles';
import CustomDialog from '../CustomDialog';
import { ChatContext } from '../../context/ChatContext';
import {
  baseUrl,
  patchRequest,
  postRequest,
} from '../../utils/services';
import { AuthContext } from '../../context/AuthContext';
import { useErrors } from '../../context/ErrorContext';

dayjs.locale('pt-br');
dayjs.extend(utc);

function ScheduleMessageDialog({
  openScheduleMessage,
  handleCloseScheduleMessage,
  sender,
  fromMsg,
}) {
  const theme = useTheme();

  const {
    allUsers,
    departments,
    showAlert,
    scheduledMessages,
    setScheduledMessages,
  } = useContext(ChatContext);

  const { user } = useContext(AuthContext);

  const { setErrorMessage } = useErrors();

  const [schedule, setSchedule] = useState({
    date: dayjs(),
    time: '',
    message: '',
    selectedDepartment: 'default',
    selectedAttendant: 'default',
    attendantsInSelectedSector: [],
    showScheduledMessages: false,
    scheduledMessages: [],
  });

  const {
    date,
    time,
    message,
    selectedDepartment,
    selectedAttendant,
    attendantsInSelectedSector,
    showScheduledMessages,
  } = schedule;

  const handleShowScheduledMessages = async () => {
    setSchedule({
      ...schedule,
      showScheduledMessages: true,
    });
  };

  const handleCloseScheduledMessages = () => {
    setSchedule({
      ...schedule,
      showScheduledMessages: false,
    });
  };

  const handleCancelScheduledMessage = async (index) => {
    const selectedScheduledMessage = scheduledMessages[index];

    const response = await patchRequest(`${baseUrl}/messages/cancelScheduledMessage/${selectedScheduledMessage.id}`);

    if (response.error) {
      return setErrorMessage('Erro ao cancelar mensagem agendada! Entre em contato com o suporte.');
    }

    setScheduledMessages((prevMessages) => prevMessages.filter((_, i) => i !== index));
  };

  const handleDepartmentChange = (e) => {
    const { value } = e.target;

    if (value === 'default') {
      setSchedule({
        ...schedule,
        selectedDepartment: value,
        selectedAttendant: 'default',
        attendantsInSelectedSector: [],
      });

      return;
    }

    const selectedSectorId = parseInt(value, 10);

    const attendantsInSelectedSector = allUsers.filter((user) => user.setores
      .some((setor) => setor.id_setor === selectedSectorId));

    setSchedule({
      ...schedule,
      selectedDepartment: selectedSectorId,
      selectedAttendant: 'default',
      attendantsInSelectedSector,
    });
  };

  const handleAttendantChange = (e) => {
    const { value } = e.target;

    if (value === 'default') {
      return setSchedule({
        ...schedule,
        selectedAttendant: value,
      });
    }

    const selectedAttendantId = parseInt(value, 10);
    setSchedule({
      ...schedule,
      selectedAttendant: selectedAttendantId,
    });
  };

  const handleDateChange = (newDate) => {
    setSchedule((prev) => ({
      ...prev,
      date: newDate,
    }));
  };

  const handleTimeChange = (event) => {
    setSchedule((prev) => ({
      ...prev,
      time: event.target.value,
    }));
  };

  const handleMessageChange = (event) => {
    setSchedule((prev) => ({
      ...prev,
      message: event.target.value,
    }));
  };

  function formatScheduledDate(dateString) {
    return dayjs(dateString).local().format('DD/MM/YYYY, HH:mm');
  }

  const handleOnConfirmScheduleMessage = () => {
    const combinedDateTime = dayjs(date)
      .hour(time.split(':')[0])
      .minute(time.split(':')[1])
      .utc();

    const formattedDateTime = combinedDateTime.format('YYYY-MM-DD HH:mm:ss');

    const payload = {
      id_usuario_created: user.id,
      sender,
      fromMsg,
      mensagem: message,
      data_agendamento: formattedDateTime,
      id_setor: selectedDepartment,
      id_usuario_destino: selectedAttendant,
    };

    const response = postRequest(`${baseUrl}/messages/createScheduledMessage`, JSON.stringify(payload));

    if (response.error) {
      return setErrorMessage('Não foi possível agendar mensagem! Entre em contato com o suporte.');
    }

    setScheduledMessages((prevScheduledMessages) => [payload, ...prevScheduledMessages]);

    handleCloseScheduleMessage();
    showAlert('Mensagem agendada com sucesso', 'success', 5000);
  };

  return (
    <CustomDialog
      open={openScheduleMessage}
      onClose={handleCloseScheduleMessage}
      message={showScheduledMessages ? 'Mensagens agendadas: ' : 'Selecione o horário e a mensagem: '}
      actionName="Agendar"
      enableConfirmButton={
        time
        && message !== ''
        && selectedDepartment !== 'default'
      }
      onConfirm={handleOnConfirmScheduleMessage}
      confirmButtonColor={theme.secondaryColor}
    >
      <ContainerSchedule>
        {showScheduledMessages
          ? (
            <div className="scheduled-messages-wrapper">
              <button
                className="close-scheduled-messages-button"
                aria-label="Sair de mensagens agendadas"
                type="button"
                onClick={handleCloseScheduledMessages}
              >
                <ArrowBackIcon style={{
                  color: theme.iconsColor,
                }}
                />
              </button>

              {scheduledMessages.length > 0 ? (scheduledMessages.map((message, index) => (
                <div
                  key={index}
                  className="scheduled-message-div"
                >
                  <h3>
                    {formatScheduledDate(message.data_agendamento)}
                  </h3>
                  <p>{message.mensagem}</p>
                  <Tooltip
                    arrow
                    title="Cancelar agendamento"
                  >
                    <button
                      className="cancel-scheduled-message-button"
                      type="button"
                      aria-label="cancelar mensagem agendada"
                      onClick={() => handleCancelScheduledMessage(index)}
                    >
                      x
                    </button>
                  </Tooltip>
                </div>
              ))) : (
                <h3 style={{
                  marginTop: '80px',
                }}
                >
                  Nenhum agendamento encontrado
                </h3>
              )}
            </div>
          )
          : (
            <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs}>
              <Badge
                badgeContent={scheduledMessages.length}
                color="error"
              >
                <button
                  type="button"
                  aria-label="Mostrar mensagens agendadas"
                  className="already-scheduled-button"
                  onClick={handleShowScheduledMessages}
                >
                  Mostrar mensagens agendadas
                </button>
              </Badge>

              <Box
                mb={2}
                sx={{
                  width: '100%',
                }}
              >
                <DatePicker
                  label="Escolha a data: *"
                  sx={{
                    width: '100%',
                  }}
                  value={date}
                  minDate={dayjs()}
                  onChange={handleDateChange}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Box>

              <Box
                mb={2}
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  label="Escolha o horário: *"
                  type="time"
                  value={time}
                  onChange={handleTimeChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300,
                  }}
                />
              </Box>

              <Box
                mb={2}
                sx={{
                  width: '100%',
                }}
              >
                <TextField
                  label="Digite sua mensagem: *"
                  value={message}
                  onChange={handleMessageChange}
                  multiline
                  rows={4}
                  fullWidth
                />
              </Box>

              <FormControl fullWidth required variant="outlined" sx={{ mb: 2 }}>
                <InputLabel htmlFor="departments">Departamento:</InputLabel>
                <Select
                  label="Departamento: "
                  native
                  value={selectedDepartment}
                  onChange={handleDepartmentChange}
                  inputProps={{
                    name: 'departments',
                    id: 'departments',
                  }}
                >
                  <option value="default">Selecione...</option>
                  {departments.map((department, index) => (
                    <option key={index} value={department.id}>
                      {department?.nome_setor}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                disabled={selectedDepartment === 'default'}
              >
                <InputLabel
                  htmlFor="attendant"
                >
                  Atendente: (Opcional)
                </InputLabel>
                <Select
                  label="Atendente: (Opcional)"
                  native
                  value={selectedAttendant}
                  onChange={handleAttendantChange}
                  inputProps={{
                    name: 'attendant',
                    id: 'attendant',
                  }}
                >
                  <option value="default">Selecione...</option>
                  {attendantsInSelectedSector.map((user, index) => (
                    <option key={index} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </LocalizationProvider>
          )}

      </ContainerSchedule>
    </CustomDialog>

  );
}

export default ScheduleMessageDialog;

ScheduleMessageDialog.propTypes = {
  openScheduleMessage: PropTypes.bool.isRequired,
  handleCloseScheduleMessage: PropTypes.func.isRequired,
  sender: PropTypes.string.isRequired,
  fromMsg: PropTypes.string.isRequired,
};
