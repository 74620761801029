import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveCircleOutlineOutlinedIcon from '@mui/icons-material/RemoveCircleOutlineOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import { useTheme } from 'styled-components';
import { Tooltip } from '@mui/material';
import { ChatContext } from '../../../context/ChatContext';
import CustomDialog from '../../CustomDialog';
import {
  MembersWrapper,
  MemberContent,
  MembersSection,
  AddMemberButton,
  NewMembersContainer,
} from './styles';
import { Container, EditProfileChatInput } from '../styles';
import { UserCheckbox } from '../../Sidebar/styles';
import { AuthContext } from '../../../context/AuthContext';
import { useErrors } from '../../../context/ErrorContext';
import { baseUrl, postRequest } from '../../../utils/services';

export default function EditProfileGroupChat({
  groupImageUrl = null,
  currentChat,
}) {
  const [groupDetails, setGroupDetails] = useState({
    groupName: currentChat.chat_name || '',
    groupDescription: currentChat.chat_description || '',
    isEditingGroupName: false,
    isAddingNewMembers: false,
    selectedMembersForAdding: [],
  });

  const {
    groupName,
    groupDescription,
    isEditingGroupName,
    isAddingNewMembers,
    selectedMembersForAdding,
  } = groupDetails;

  const {
    setIsEditingProfileGroupChat,
    allUsers,
  } = useContext(ChatContext);

  const { user } = useContext(AuthContext);

  const theme = useTheme();

  const { setErrorMessage } = useErrors();

  const handleCancelEditingProfileGroupChat = () => {
    setIsEditingProfileGroupChat(false);
  };

  const handleOnChangeGroupName = () => {

  };

  const handleOpenIsEditingGroupName = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isEditingGroupName: true,
    }));
  };

  const handleCloseIsEditingGroupName = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isEditingGroupName: false,
    }));
  };

  const handleOnChangeChatDescription = () => {

  };

  const handleSaveChatChanges = () => {

  };

  const handleOpenAddingNewMembers = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isAddingNewMembers: true,
    }));
  };

  const handleCloseAddingNewMembers = () => {
    setGroupDetails((previousGroupDetails) => ({
      ...previousGroupDetails,
      isAddingNewMembers: false,
    }));
  };

  const handleConfirmAddNewMembers = async () => {
    try {
      const payload = {
        membersId: selectedMembersForAdding,
        chatId: currentChat.id,
      };
      const response = await postRequest(`${baseUrl}/chats/addNewMembersForGroupChat`, JSON.stringify(payload));
    } catch (error) {
      setErrorMessage('Não foi possível adicionar participantes! Entre em contato com o suporte');
    }
  };

  const handleSelectNewMembers = (userId) => {
    if (selectedMembersForAdding.includes(userId)) {
      setGroupDetails((previousGroupDetails) => ({
        ...previousGroupDetails,
        selectedMembersForAdding: selectedMembersForAdding.filter((id) => id !== userId),
      }));
    } else {
      setGroupDetails((previousGroupDetails) => ({
        ...previousGroupDetails,
        selectedMembersForAdding: [...selectedMembersForAdding, userId],
      }));
    }
  };

  const groupMembers = [{ memberId: user.id, memberName: 'Eu' }, ...currentChat.members];

  return (
    <Container>
      <button
        type="button"
        aria-label="cancelar edição de contato"
        onClick={handleCancelEditingProfileGroupChat}
        className="cancel-edit-contact-button"
      >
        <CloseOutlinedIcon style={{
          color: theme.iconsColor,
          fontSize: '30px',
        }}
        />
      </button>

      <div
        className="profile-img-wrapper"
      >

        {
        isEditingGroupName
          ? (
            <div>
              <EditProfileChatInput
                value={groupName}
                placeholder="Editar nome do contato"
                onChange={handleOnChangeGroupName}
              />
              <Tooltip
                arrow
                title="Cancelar alteração"
              >
                <button
                  type="button"
                  aria-label="Cancelar alteração no nome do contato"
                  onClick={handleCloseIsEditingGroupName}
                >
                  <CloseOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
          : (
            <div>
              <h1>{groupName}</h1>
              <Tooltip
                arrow
                title="Alterar nome do contato"
              >
                <button
                  type="button"
                  aria-label="Alterar nome do contato"
                  onClick={handleOpenIsEditingGroupName}
                >
                  <ModeEditOutlineOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
        }

      </div>
      <div className="profile-fields-wrapper">
        <h2>
          Descrição do grupo
        </h2>
        <textarea
          value={groupDescription}
          placeholder="Escreva aqui uma descrição para esse grupo."
          onChange={handleOnChangeChatDescription}
        />
        <button
          aria-label="Salvar alterações"
          className="save-changes-button"
          type="button"
          onClick={handleSaveChatChanges}
          disabled={groupName === currentChat.chat_name
            && groupDescription === currentChat.chat_description}
        >
          Salvar alterações
        </button>
      </div>
      <MembersSection>
        <h2>
          Participantes (
          {currentChat.members.length}
          )
        </h2>
        <MembersWrapper>
          {groupMembers.map((member, index) => (
            <MemberContent
              key={index}
              id={index}
            >
              <p>
                {member.memberName}
              </p>
              <Tooltip
                title="Remover participante"
                arrow
                placement="top"
              >
                <button
                  aria-label="Remover participante"
                  type="button"
                >
                  <RemoveCircleOutlineOutlinedIcon />
                </button>
              </Tooltip>
            </MemberContent>
          ))}
        </MembersWrapper>

        <AddMemberButton
          className="save-changes-button"
          aria-label="Adicionar participante"
          type="button"
          onClick={handleOpenAddingNewMembers}
        >
          <GroupAddOutlinedIcon />
          Adicionar
        </AddMemberButton>
      </MembersSection>

      <CustomDialog
        open={isAddingNewMembers}
        onClose={handleCloseAddingNewMembers}
        onConfirm={handleConfirmAddNewMembers}
        message="Selecione os novos participantes: "
        actionName="Confirmar"
        confirmButtonColor={theme.secondaryColor}
      >
        <NewMembersContainer>
          {allUsers
            .filter(
              (u) => u.id !== user.id
                && !currentChat.members.some((member) => member.memberId === u.id),
            )
            .map((user) => (
              <UserCheckbox key={user.id}>
                <label
                  htmlFor={`user-${user.id}`}
                >
                  <input
                    id={`user-${user.id}`}
                    type="checkbox"
                    checked={selectedMembersForAdding.includes(user.id)}
                    onChange={() => handleSelectNewMembers(user.id)}
                  />
                  {user.name}
                </label>
              </UserCheckbox>
            ))}
        </NewMembersContainer>
      </CustomDialog>
    </Container>
  );
}

EditProfileGroupChat.propTypes = {
  groupImageUrl: PropTypes.string,
  currentChat: PropTypes.shape({
    id: PropTypes.number.isRequired,
    ambiente: PropTypes.string.isRequired,
    is_internal_chat: PropTypes.number,
    chat_name: PropTypes.string,
    chat_description: PropTypes.string,
    lastMessage: PropTypes.string.isRequired,
    mediaType: PropTypes.string.isRequired,
    mediaUrl: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    senderId: PropTypes.number.isRequired,
    unreadCount: PropTypes.number,
    is_group: PropTypes.number,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        memberId: PropTypes.number.isRequired,
        memberName: PropTypes.string.isRequired,
      }),
    ).isRequired,
  }).isRequired,
};
