import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import he from 'he';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AddIcon from '@mui/icons-material/Add';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import CancelIcon from '@mui/icons-material/Cancel';
import {
  Tooltip, Chip, IconButton,
} from '@mui/material';
import { useTheme } from 'styled-components';
import { Container, EditProfileChatInput } from './styles';
import {
  baseUrl, deleteRequest, formatPhoneNumber, patchRequest,
  postRequest,
} from '../../utils/services';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { useErrors } from '../../context/ErrorContext';

export default function EditProfileChat({
  profileImageURL = null,
  contactName,
  contactPhone,
}) {
  const { user } = useContext(AuthContext);
  const {
    currentChat,
    setCurrentChat,
    setOpenTickets,
    setSortedChats,
    setUserChatsQueue,
    messages,
    setIsEditingProfileChat,
    showAlert,
    chatTags,
    setChatTags,
  } = useContext(ChatContext);

  const { setErrorMessage } = useErrors();

  const theme = useTheme();

  const [isEditingContactName, setIsEditingContactName] = useState(false);
  const [newContactName, setNewContactName] = useState(currentChat?.customName || null);
  const [contactNote, setContactNote] = useState(currentChat?.obs || null);

  const {
    associatedTags, availableTags, newTag, currentContactId,
  } = chatTags;

  const handleOpenIsEditingContactName = () => {
    setIsEditingContactName(true);
  };

  const handleCloseIsEditingContactName = () => {
    setIsEditingContactName(false);
  };

  const handleOnChangeContactName = (e) => {
    const { value } = e.target;
    setNewContactName(value);
  };

  const handleOnChangeContactNote = (e) => {
    const { value } = e.target;
    setContactNote(value);
  };

  const handleCancelEditingProfileChat = () => {
    setIsEditingProfileChat(false);
  };

  const handleChatMarkAsUnread = () => {
    if (currentChat.status === 1) {
      setUserChatsQueue((prevQueue) => prevQueue
        .map((chat) => (chat.id === currentChat.id
          ? { ...chat, unreadCount: 1 }
          : chat)));
    }

    if (currentChat.status === 2 && currentChat.usuario_id === user.id) {
      setOpenTickets((prevTickets) => prevTickets.map((chat) => (chat.id === currentChat.id
        ? { ...chat, unreadCount: 1 }
        : chat)));
    }

    setSortedChats((prevSortedChats) => prevSortedChats.map((chat) => (chat.id === currentChat.id
      ? { ...chat, unreadCount: 1 }
      : chat)));

    const lastMessageIdFromOtherUser = messages.find((message) => message.fromMe === 0);
    const lastMessageId = lastMessageIdFromOtherUser ? lastMessageIdFromOtherUser.id : null;

    const response = patchRequest(`${baseUrl}/chats/updateToUnread`, JSON.stringify({ id: lastMessageId }));

    if (response.error) {
      return setErrorMessage('Erro ao marcar conversa como não lida. Entre em contato com o suporte.');
    }
  };

  const customName = newContactName
  || currentChat?.nome
  || formatPhoneNumber(currentChat?.fromMsg);

  const obs = contactNote || currentChat.obs || 'Escreva aqui uma observação sobre esse atendimento.';

  const handleSaveChanges = async () => {
    try {
      const encodedCustomName = he.encode(customName, {
        useNamedReferences: true,
        decimal: true,
        allowUnsafeSymbols: true,
      });

      await patchRequest(`${baseUrl}/chats/updateProfileChat`, JSON.stringify({
        sender: currentChat.sender,
        fromMsg: currentChat.fromMsg,
        obs,
        customName: encodedCustomName,
      }));

      const updatedObs = contactNote !== '' || !currentChat.obs
        ? contactNote
        : currentChat.obs;

      const updatedCustomName = newContactName !== '' || !currentChat.customName
        ? newContactName
        : currentChat.customName;

      setCurrentChat((prevCurrentChat) => ({
        ...prevCurrentChat,
        obs: updatedObs,
        customName: updatedCustomName,
      }));

      if (currentChat.usuario_id === user.id) {
        setOpenTickets((prevOpenTickets) => prevOpenTickets
          .map((ticket) => (ticket.sender === currentChat.sender
          && ticket.fromMsg === currentChat.fromMsg
            ? { ...ticket, obs: updatedObs, customName: updatedCustomName }
            : ticket)));
      }

      setSortedChats((prevSortedChats) => prevSortedChats
        .map((chat) => (chat.sender === currentChat.sender
          && chat.fromMsg === currentChat.fromMsg
          ? { ...chat, obs: updatedObs, customName: updatedCustomName }
          : chat)));

      showAlert('Alterações salvas com sucesso', 'success', 5000);
      handleCloseIsEditingContactName();
    } catch (error) {
      setErrorMessage('Erro ao alterar dados do contato. Entre em contato com o suporte');
    }
  };

  const handleAddNewTag = async () => {
    if (!newTag || newTag.trim() === '') {
      return showAlert('O nome da tag não pode estar vazio', 'warning', 5000);
    }

    if (associatedTags
      .some((tag) => tag.tag_name === newTag)
      || availableTags.some((tag) => tag.tag_name === newTag)) {
      return showAlert('Uma tag com esse nome já está disponível. Selecione-a no campo "Tags disponíveis".', 'warning', 5000);
    }

    const tagPayload = {
      tag_name: newTag,
      ambiente: user.ambiente,
      contactId: currentContactId || associatedTags[0].contactId,
    };

    const response = await postRequest(`${baseUrl}/chats/createTag`, JSON.stringify(tagPayload));

    if (response.error) {
      return setErrorMessage('Não foi possível criar nova tag! Entre em contato com o suporte.');
    }

    setChatTags((prevState) => ({
      ...prevState,
      associatedTags: [...prevState.associatedTags, { ...tagPayload, id: response }],
      newTag: '',
    }));
  };

  const handleAddAvailableTag = async (tag) => {
    const tagPayload = {
      tagId: tag.id,
      contactId: currentContactId || associatedTags[0].contactId,
    };

    const response = await postRequest(`${baseUrl}/chats/createTagRelationship`, JSON.stringify(tagPayload));

    if (response.error) {
      return setErrorMessage('Não foi possível associar a tag! Entre em contato com o suporte.');
    }

    setChatTags((prevState) => ({
      ...prevState,
      associatedTags: [...prevState.associatedTags, tag],
      availableTags: prevState.availableTags.filter((t) => t.id !== tag.id),
    }));
  };

  const handleDeleteTag = async (tagToDelete) => {
    const response = await deleteRequest(`${baseUrl}/chats/deleteTag/${tagToDelete.id}/${associatedTags[0].contactId}`);

    if (response?.error) {
      return setErrorMessage('Não foi possível excluir tag! Entre em contato com o suporte');
    }

    setChatTags((prevState) => ({
      ...prevState,
      associatedTags: prevState.associatedTags.filter((tag) => tag !== tagToDelete),
      availableTags: [...prevState.availableTags, tagToDelete],
    }));
  };

  const handleNewTagChange = (e) => {
    const newTagValue = e.target.value;
    setChatTags((prevState) => ({
      ...prevState,
      newTag: newTagValue,
    }));
  };

  return (
    <Container>
      <button
        type="button"
        aria-label="cancelar edição de contato"
        onClick={handleCancelEditingProfileChat}
        className="cancel-edit-contact-button"
      >
        <CloseOutlinedIcon style={{
          color: theme.iconsColor,
          fontSize: '30px',
        }}
        />
      </button>
      <div
        className="profile-img-wrapper"
      >
        <img
          src={profileImageURL || 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635'}
          alt="Foto do usuário"
        />

        {
        isEditingContactName
          ? (
            <div>
              <EditProfileChatInput
                value={newContactName}
                placeholder={currentChat.customName
                  ? currentChat.customName
                  : 'Editar nome do contato'}
                onChange={handleOnChangeContactName}
              />
              <Tooltip
                arrow
                title="Cancelar alteração"
              >
                <button
                  type="button"
                  aria-label="Cancelar alteração no nome do contato"
                  onClick={handleCloseIsEditingContactName}
                >
                  <CloseOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
          : (
            <div>
              <h1>{contactName}</h1>
              <Tooltip
                arrow
                title="Alterar nome do contato"
              >
                <button
                  type="button"
                  aria-label="Alterar nome do contato"
                  onClick={handleOpenIsEditingContactName}
                >
                  <ModeEditOutlineOutlinedIcon />
                </button>
              </Tooltip>
            </div>
          )
        }
        <Tooltip
          arrow
          title="Número do contato"
        >
          <span>
            <PhoneAndroidIcon />
            {formatPhoneNumber(contactPhone)}
          </span>
        </Tooltip>
      </div>
      <div className="not-readed-div">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="16" height="16">
          <circle cx="50" cy="50" r="50" fill="#D32F2F" />
        </svg>
        <button
          type="button"
          aria-label="Marcar chat como não lido"
          onClick={handleChatMarkAsUnread}
        >
          Marcar como não lida
        </button>
      </div>
      <div
        className="profile-fields-wrapper"
      >
        <h2>Observações</h2>
        <textarea
          value={contactNote}
          placeholder="Escreva aqui uma observação sobre esse atendimento."
          onChange={handleOnChangeContactNote}
        />
        <button
          aria-label="Salvar alterações"
          className="save-changes-button"
          type="button"
          onClick={handleSaveChanges}
          disabled={newContactName === currentChat.customName
            && contactNote === currentChat.obs}
        >
          Salvar alterações
        </button>
        <div className="tag-fields-wrapper">
          <h2>Tags associadas</h2>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              margin: '20px 0',
              border: '1px solid #AEBAC1',
              borderRadius: '16px',
              padding: '10px',
            }}
          >
            {associatedTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag.tag_name}
                onDelete={() => handleDeleteTag(tag)}
                deleteIcon={<CancelIcon style={{ color: '#F5F5F5' }} />}
                sx={{
                  backgroundColor: theme.secondaryColor,
                  color: 'white',
                }}
              />
            ))}
          </div>

          <h2>Tags disponíveis</h2>
          <div
            className="available-tags-div"
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              maxHeight: '200px',
              overflowY: 'auto',
              margin: '20px 0',
              border: '1px solid #AEBAC1',
              borderRadius: '16px',
              padding: '10px',
            }}
          >
            {availableTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag.tag_name}
                onDelete={() => handleAddAvailableTag(tag)}
                deleteIcon={<AddCircleRoundedIcon style={{ color: '#F5F5F5' }} />}
                sx={{
                  backgroundColor: theme.secondaryFontColor,
                  color: 'white',
                }}
              />
            ))}
          </div>

          <EditProfileChatInput
            placeholder="Adicionar Tag"
            size="small"
            value={newTag}
            onChange={handleNewTagChange}
            onKeyDown={(e) => e.key === 'Enter' && handleAddNewTag()}
            style={{ width: 'calc(100% - 48px)' }}
          />
          <Tooltip
            arrow
            title="Adicionar Tag"
          >
            <IconButton onClick={handleAddNewTag} aria-label="Adicionar tag">
              <AddIcon color={theme.secondaryColor} />
            </IconButton>
          </Tooltip>
        </div>
      </div>

    </Container>
  );
}

EditProfileChat.propTypes = {
  profileImageURL: PropTypes.string,
  contactName: PropTypes.string.isRequired,
  contactPhone: PropTypes.string.isRequired,
};
