import { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material';
import { Container } from './styles';
import {
  getRequest, baseUrl, postRequest, formatPhoneNumber,
} from '../../utils/services';
import { ChatContext } from '../../context/ChatContext';
import usePhoneFormatter from '../../hooks/usePhoneFormatter';

import { AuthContext } from '../../context/AuthContext';
import { useErrors } from '../../context/ErrorContext';

export default function NewChat({ initialParams = null, onCloseNewChat }) {
  const [message, setMessage] = useState('');
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  const { user } = useContext(AuthContext);
  const {
    sendMessage,
    selectedSenderKey,
    setSelectedSenderKey,
    currentChat,
    setCurrentChat,
    isOpenTicketsLoading,
    updateUserChatFromQueueToChats,
    fetchMessages,
    kanban,
    setKanban,
  } = useContext(ChatContext);

  const { setErrorMessage } = useErrors();

  const [phone, handlePhoneChange, setPhone, getRawPhoneNumber] = usePhoneFormatter();

  const handleMessageInput = (event) => {
    const { value } = event.target;
    setMessage(value);
  };

  const handleSenderChange = (e) => setSelectedSenderKey(e.target.value);

  useEffect(() => {
    if (initialParams) {
      setPhone(initialParams.telefone);
      setMessage(initialParams.msg);
    }
  }, [initialParams, setPhone, setMessage]);

  const senders = Object.entries(user?.senders || {});

  const handleFindChat = async (sender, fromMsg) => {
    let newCurrentChat = currentChat;

    const response = await getRequest(`${baseUrl}/chats/find/${sender}/${fromMsg}`);

    if (response.error && response.statusCode === 404) {
      const createTicketResponse = await postRequest(`${baseUrl}/chats/createTicket`, JSON.stringify({
        ambiente: user.ambiente,
        sender,
        fromMsg,
        userId: user.id,
        setor_id: user.setores[0].id_setor,
      }));

      if (createTicketResponse.error) {
        setErrorMessage('Erro ao criar ticket. Entre em contato com o suporte.');
        onCloseNewChat();
        return console.error('Error creating ticket: ', createTicketResponse.error);
      }

      const getTicketByIdResponse = await getRequest(`${baseUrl}/chats/findById/${createTicketResponse}`);

      if (getTicketByIdResponse.error) {
        setErrorMessage('Erro ao encontrar ticket. Entre em contato com o suporte.');
        return console.error('Error finding ticket: ', getTicketByIdResponse.error);
      }

      newCurrentChat = getTicketByIdResponse;
      setCurrentChat(newCurrentChat);

      await updateUserChatFromQueueToChats(newCurrentChat);
      return;
    }

    if (!response.error) {
      newCurrentChat = response;
      setCurrentChat(newCurrentChat);
      setKanban({
        ...kanban,
        showKanbanSection: false,
      });
      await updateUserChatFromQueueToChats(newCurrentChat);
    }
  };

  const handleSendMessage = async () => {
    setIsSendingMessage(true);

    const response = await sendMessage(message, selectedSenderKey, getRawPhoneNumber(), setMessage);

    if (!response || response.error) {
      setErrorMessage('Não foi possível enviar mensagem ao telefone inserido. Verifique se o destinatário realmente possui WhatsApp');
      onCloseNewChat();
    }

    const currentChatSenderAndFromMsg = {
      sender: selectedSenderKey,
      fromMsg: response.data.key.remoteJid,
    };

    await handleFindChat(
      selectedSenderKey,
      response.data.key.remoteJid,
    );

    fetchMessages(currentChatSenderAndFromMsg, 1);
    setIsSendingMessage(false);
    onCloseNewChat();
  };

  return (
    <Container>
      <h1>Inicie uma nova conversa</h1>
      <div>
        <p>Telefone: </p>
        <input
          value={phone}
          onChange={handlePhoneChange}
          type="tel"
          placeholder="DDD + número"
        />
        <p>Mensagem: </p>
        <textarea
          value={message}
          type="text"
          onChange={handleMessageInput}
        />
        <p>Selecionar remetente: </p>
        <select
          value={selectedSenderKey}
          onChange={handleSenderChange}
        >
          <option value="">Selecione um remetente</option>
          {senders.map(([key, sender]) => (
            <option key={key} value={key}>
              {formatPhoneNumber(String(sender.telefone))}
              {' '}
            </option>
          ))}
        </select>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      >
        <button
          disabled={!phone || message === '' || isOpenTicketsLoading || !selectedSenderKey}
          className="send-message-button"
          type="button"
          onClick={handleSendMessage}
        >
          {isSendingMessage ? 'Enviando...' : 'Enviar'}
        </button>
        {isSendingMessage && (
          <CircularProgress
            size="30px"
            variant="indeterminate"
          />
        )}
      </div>
    </Container>
  );
}

NewChat.propTypes = {
  onCloseNewChat: PropTypes.func,
  initialParams: PropTypes.shape({
    telefone: PropTypes.string.isRequired,
    msg: PropTypes.string.isRequired,
  }),
};
