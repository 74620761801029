import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DDD;
  padding: 10px 2px;
  background: ${({ theme }) => theme.buttonBackground};
  transition: 0.2s;
  width: 100%;
  justify-content: space-between;


  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin: 0 0 0 10px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.hoverButtonBackground};
  }

  .last-message-container > p {
    padding-top: 2px;
    color: ${({ theme }) => theme.secondaryFontColor};
    margin-right: 5px;
  }

  .last-message-container > p:nth-child(2) {
    display: flex;
    align-items: center;
    margin-left: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    color: ${({ theme }) => theme.secondaryFontColor};
  }


  .sidebar-chat-container {
    width: 60%;
    display: flex;
    margin-left: 10px;
    flex-direction: column;
    justify-content: start;

  }

  .time-and-notifications-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 20%;
    margin-right: 10px;
  }

  h1 {
    text-align: start;
    font-size: 18px;
    font-weight: 600;
  }

  .notifications-div {
    gap: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    button {
      background-color: transparent;
    }
  }

  .name-and-phone-div {
    display: flex;
    flex-direction: row;
    align-items: center;

    h1 {
      display: flex;
      align-items: center;
      font-size: 18px;
      max-width: 100%;
      width: auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

    }

    span {
      margin-left: 5px;
      font-size: 12px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .last-message-container {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 5px;
    color: black;
  }

  .last-message-container span {
    font-size: 14px;
    padding: 0;
    margin-left: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    width: auto;
    text-align: start;
    color: ${({ theme }) => theme.secondaryFontColor};

  }

  .type-message-tag {
    display: flex;
    align-items: end;
    overflow: hidden;
    text-overflow: ellipsis;
    span {
      font-size: 14px;
      color: ${({ theme }) => theme.secondaryFontColor};
    }
  }

`;
