/* eslint-disable jsx-a11y/label-has-associated-control */
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VideocamIcon from '@mui/icons-material/Videocam';
import BookmarkAddedIcon from '@mui/icons-material/BookmarkAdded';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import ArchiveIcon from '@mui/icons-material/Archive';
import ImageIcon from '@mui/icons-material/Image';
import { useTheme } from 'styled-components';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  Tooltip,
  Menu,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@mui/material';

import PropTypes from 'prop-types';

import { useContext, useState } from 'react';
import {
  baseUrl, getRequest, formatPhoneNumber, patchRequest,
} from '../../utils/services';
import { Container } from './styles';
import { ChatContext } from '../../context/ChatContext';
import PopOverMenu from '../PopOverMenu';
import CustomDialog from '../CustomDialog';
import ScheduleMessageDialog from '../ScheduleMessageDialog';
import useWindowSize from '../../hooks/useWindowSize';

import TransferTicketSection from '../TransferTicketSection';
import { AuthContext } from '../../context/AuthContext';
import { useErrors } from '../../context/ErrorContext';

export default function ChatHeader({
  contactName,
  isOnline,
  isInternalChat,
  ipbxNumber,
  leadOrigin,
  attendantName,
}) {
  const {
    currentChat,
    departments,
    selectedDepartment,
    selectedAttendant,
    setCurrentChat,
    setMessages,
    handleInputMessageChange,
    setInternalChats,
    setArchivedInternalChats,
    setOpenArchivedInternalChats,
    setSelectedImageToSend,
    setSelectedVideoToSend,
    setSelectedDocumentToSend,
    setScheduledMessages,
    updateTicketToClosed,
    handleTransferTicket,
    handleCreateTicketClosedNotification,
    openTransferModal,
    setOpenTransferModal,
    setIsEditingProfileChat,
    setIsEditingProfileGroupChat,
    kanban,
    chatTags,
    setChatTags,
  } = useContext(ChatContext);

  const { user } = useContext(AuthContext);

  const { currentChatKanbans } = kanban;

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const { width } = useWindowSize();

  const theme = useTheme();

  const { setErrorMessage } = useErrors();

  const [openTicketModal, setOpenTicketModal] = useState(false);

  const [openScheduleMessage, setOpenScheduleMessage] = useState(false);

  const handleOpenTransferModal = () => {
    setOpenTransferModal(true);
  };

  const handleCloseTransferModal = () => {
    setOpenTransferModal(false);
  };

  const handleOpenScheduleMessage = async () => {
    const response = await getRequest(`${baseUrl}/messages/scheduled/${currentChat.sender}/${currentChat.fromMsg}`);

    if (response.error) {
      return setErrorMessage('Não foi possível carregar as mensagens agendadas! Entre em contato o suporte.');
    }

    setScheduledMessages(response);
    setOpenScheduleMessage(true);
  };

  const handleCloseScheduleMessage = () => {
    setOpenScheduleMessage(false);
  };

  const handleOpenTicketModal = () => {
    setOpenTicketModal(true);
  };

  const handleCloseTicketModal = () => {
    setOpenTicketModal(false);
  };

  const handleOpenEditProfileChat = async () => {
    /* if (currentChat.is_internal_chat === 1 && currentChat.is_group === 1) {
      setIsEditingProfileGroupChat(true);
      return;
    } */

    if (currentChat?.is_internal_chat === 1) return;

    const associatedTags = await getRequest(`${baseUrl}/chats/associatedTags/${user.ambiente}/${currentChat.fromMsg}`);

    let updatedChatTags = { ...chatTags };

    if (associatedTags.length === 0) {
      const currentContactId = await getRequest(`${baseUrl}/chats/getTagsContactId/${user.ambiente}/${currentChat.fromMsg}`);

      updatedChatTags = {
        ...updatedChatTags,
        currentContactId,
      };
    }

    const availableTags = await getRequest(`${baseUrl}/chats/availableTags/${user.ambiente}`);

    if (associatedTags.error || availableTags.error) {
      setIsEditingProfileChat(true);
      return setErrorMessage('Não foi possível carregar tags! Entre em contato com o suporte.');
    }

    const filteredAvailableTags = availableTags.filter(
      (availableTag) => !associatedTags
        .some((associatedTag) => associatedTag.id === availableTag.id),
    );

    updatedChatTags = {
      ...updatedChatTags,
      associatedTags,
      availableTags: filteredAvailableTags,
    };

    setChatTags(updatedChatTags);
    setIsEditingProfileChat(true);
  };

  const handleCloseTicket = () => {
    updateTicketToClosed();
    handleCreateTicketClosedNotification(
      currentChat.sender,
      currentChat.fromMsg,
      user.ambiente,
    );
    setCurrentChat(null);
  };

  const handleBackMenuClick = () => {
    setIsEditingProfileGroupChat(false);
    setCurrentChat(null);
    setMessages([]);
  };

  const handleOnConfirmTransferTicket = () => {
    handleTransferTicket(
      selectedAttendant,
      currentChat.sender,
      currentChat.fromMsg,
      selectedDepartment,
      user.ambiente,
    );
    setCurrentChat(null);
  };

  const handleArchiveInternalChat = async () => {
    try {
      const payload = {
        chatId: currentChat.id,
        userId: user.id,
      };

      await patchRequest(`${baseUrl}/chats/archiveInternalChat`, JSON.stringify(payload));
    } catch (error) {
      setErrorMessage('Não foi possível arquivar essa conversa! Entre em contato com o suporte.');
    } finally {
      const currentChatArchived = {
        ...currentChat,
        arquivado: 1,
      };

      setInternalChats((prevInternalChats) => prevInternalChats
        .map((internalChat) => {
          if (internalChat.id === currentChat?.id) {
            return { ...internalChat, arquivado: 1 };
          }
          return internalChat;
        }));

      setArchivedInternalChats((prevArchived) => [currentChatArchived, ...prevArchived]);

      setCurrentChat(null);
    }
  };

  const handleUnarchiveInternalChat = async () => {
    try {
      const payload = {
        chatId: currentChat.id,
        userId: user.id,
      };

      await patchRequest(`${baseUrl}/chats/unarchiveInternalChat`, JSON.stringify(payload));
    } catch (error) {
      setErrorMessage('Não foi possível arquivar essa conversa! Entre em contato com o suporte.');
    } finally {
      setArchivedInternalChats((prevArchived) => prevArchived
        .filter((archived) => archived.id !== currentChat.id));

      setCurrentChat((prevCurrentChat) => {
        return { ...prevCurrentChat, arquivado: 0 };
      });

      setInternalChats((prevInternalChats) => prevInternalChats
        .map((internalChat) => {
          if (internalChat.id === currentChat?.id) {
            return { ...internalChat, arquivado: 0 };
          }
          return internalChat;
        }));

      setOpenArchivedInternalChats(false);
    }
  };

  const handleImageUpload = (e) => {
    const { files } = e.target;
    setSelectedImageToSend(files);
  };

  const handleVideoUpload = (e) => {
    const { files } = e.target;
    setSelectedVideoToSend(files);
  };

  const handleDocumentsUpload = (e) => {
    const { files } = e.target;
    setSelectedDocumentToSend(files);
  };

  const isInternalChatOptions = currentChat?.arquivado === 1
    ? {
      title: 'Desarquivar conversa',
      ariaLabel: 'Desarquivar conversa',
      icon: <UnarchiveIcon style={{
        color: theme.iconsColor,
      }}
      />,
      dialogMessage: 'Tem certeza que deseja desarquivar essa conversa?',
      actionName: 'Desarquivar',
      confirmButtonColor: theme.secondaryColor,
    }
    : {
      title: 'Arquivar conversa',
      ariaLabel: 'Arquivar conversa',
      icon: <ArchiveIcon style={{
        color: theme.iconsColor,
      }}
      />,
      dialogMessage: 'Tem certeza que deseja arquivar essa conversa?',
      actionName: 'Arquivar',
      confirmButtonColor: theme.secondaryColor,
    };

  const externalChatOptions = {
    title: 'Fechar chamado',
    ariaLabel: 'Fechar chamado',
    icon: <PhoneDisabledIcon />,
    dialogMessage: `Tem certeza que deseja fechar o chamado com ${contactName}?`,
    actionName: 'Fechar',
    confirmButtonColor: theme.dangerButton,
  };

  const chatOptions = isInternalChat ? isInternalChatOptions : externalChatOptions;

  const currentChatDepartment = departments
    .find((department) => department.id === currentChat.setor_id);

  return (
    <Container>
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        <button
          type="button"
          aria-label="Voltar para tela inicial"
          onClick={handleBackMenuClick}
          style={{
            width: '50px',
            backgroundColor: theme.buttonBackground,
          }}
        >
          <ArrowBackIcon style={{ color: theme.iconsColor }} />
        </button>

        <button
          aria-label="Botão de perfil do chat"
          className="profile-chat-button"
          onClick={handleOpenEditProfileChat}
          type="button"
        >
          <img
            src={currentChat.profilePic
              ? currentChat.profilePic
              : 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png?20201213175635'}
            alt="Imagem de perfil do usuário"
          />

          <div
            className="number-and-ipbx-div"
            style={{
              flexDirection: width < 1170 || currentChat?.is_group === 1
                ? 'column'
                : 'row',
              alignItems: 'start',
            }}
          >
            <h1>{contactName}</h1>

            <div className="current-chat-members-group-span">
              {
              isInternalChat && currentChat.is_group === 1 && (
                currentChat.members.map((member, index) => (
                  <span>
                    {
                    index === currentChat.members.length - 1
                      ? `${member.memberName}.`
                      : `${member.memberName}, `
                    }
                  </span>
                ))
              )
            }
            </div>
            <span>
              <Tooltip
                arrow
                title="Número do contato"
              >
                {currentChat?.nome ? formatPhoneNumber(currentChat.fromMsg) : ''}
              </Tooltip>
              {isOnline
                ? 'Online'
                : ''}
            </span>
            <Tooltip
              arrow
              title="Número IPBX"
            >
              { ipbxNumber && (
              <span
                className="ipbxNumber-span"
                style={{
                  marginLeft: width < 1170 ? '' : '20px',
                  marginTop: width > 1170 ? '' : '5px',
                }}
              >
                {ipbxNumber}
              </span>
              )}
            </Tooltip>
            {leadOrigin && currentChat?.status !== 3 && (
            <Tooltip
              arrow
              title="Origem do lead"
            >
              <span
                className="lead-origin-span"
                style={{
                  marginLeft: width < 1170 ? '' : '20px',
                  marginTop: width > 1170 ? '' : '5px',
                }}
              >
                {leadOrigin}
              </span>
            </Tooltip>
            )}
            {currentChatDepartment?.nome_setor && currentChat?.status !== 3 && (
            <Tooltip
              arrow
              title="Departamento do chamado"
            >
              <span
                className="department-span"
                style={{
                  marginLeft: width < 1170 ? '' : '20px',
                  marginTop: width > 1170 ? '' : '5px',
                }}
              >
                {currentChatDepartment?.nome_setor}
              </span>
            </Tooltip>
            )}
            {attendantName && currentChat?.status !== 3 && (
              <Tooltip
                arrow
                title="Nome do atendente"
              >
                <span
                  className="attendant-name-span"
                  style={{
                    marginLeft: width < 1170 ? '' : '20px',
                    marginTop: width > 1170 ? '' : '5px',
                  }}
                >
                  {attendantName}
                </span>
              </Tooltip>
            )}
          </div>

        </button>
        {
    currentChatKanbans.length > 0 && (
    <>
      {
        !isInternalChat && (
          <Tooltip
            arrow
            title="Kanbans adicionados"
            type="button"
          >
            <button
              aria-label="Kanbans com esse chat: "
              type="button"
              onClick={handleMenuOpen}
              style={{
                marginLeft: '10px',
                padding: '8px',
                border: 'none',
                backgroundColor: 'transparent',
                cursor: 'pointer',
              }}
            >
              <BookmarkAddedIcon style={{
                color: theme.iconsColor,
              }}
              />
            </button>
          </Tooltip>
        )
      }

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          style: { maxHeight: 300, width: '300px' },
        }}
      >
        <TableContainer style={{ maxHeight: 250 }}>
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell><strong>Quadro</strong></TableCell>
                <TableCell align="left"><strong>Coluna</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentChatKanbans
                .map((board, index) => (
                  <TableRow key={index}>
                    <TableCell>{board.board_name}</TableCell>
                    <TableCell align="left" style={{ color: 'gray' }}>
                      {board.column_name}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Menu>
    </>
    )
}

      </div>

      {width > 1150 ? (
        <div className="action-chat-buttons">
          <Tooltip arrow title={chatOptions.title}>
            <button
              className="close-ticket-button"
              type="button"
              aria-label={chatOptions.ariaLabel}
              onClick={handleOpenTicketModal}
            >
              {chatOptions.icon}
            </button>

            <CustomDialog
              contactName={isInternalChat ? '' : `${contactName}?`}
              open={openTicketModal}
              onClose={handleCloseTicketModal}
              onConfirm={() => {
                if (isInternalChat) {
                  if (currentChat?.arquivado === 1) {
                    return handleUnarchiveInternalChat();
                  }
                  return handleArchiveInternalChat();
                }
                handleCloseTicket();
              }}
              message={chatOptions.dialogMessage}
              actionName={chatOptions.actionName}
              confirmButtonColor={chatOptions.confirmButtonColor}
            />
          </Tooltip>

          {!isInternalChat && (
            <>
              <Tooltip
                arrow
                title="Transferir chamado"
              >
                <button
                  type="button"
                  aria-label="Tranferir chamado"
                  className="transfer-ticket-button"
                  onClick={handleOpenTransferModal}
                >
                  <SyncAltIcon />
                </button>

                <CustomDialog
                  open={openTransferModal}
                  onClose={handleCloseTransferModal}
                  message="Para qual departamento deseja transferir?"
                  enableConfirmButton={selectedDepartment !== 'default'}
                  actionName="Transferir"
                  onConfirm={handleOnConfirmTransferTicket}
                  confirmButtonColor={theme.secondaryColor}
                >
                  <TransferTicketSection />
                </CustomDialog>
              </Tooltip>
              <Tooltip
                arrow
                title="Agendar mensagem"
              >
                <button
                  type="button"
                  className="schedule-message-button"
                  aria-label="Agendar mensagem"
                  onClick={handleOpenScheduleMessage}
                >
                  <ScheduleSendIcon style={{ color: theme.iconsColor }} />
                </button>
              </Tooltip>
            </>
          )}

          <PopOverMenu
            tooltipTitle="Adicionar mídias"
            icon={<AttachFileIcon style={{ color: theme.iconsColor }} />}
          >

            <button
              type="button"
              aria-label="Botão de adicionar mídias"
              style={{
                width: '100%',
              }}
            >
              <ImageIcon style={{
                color: theme.iconsColor,
                marginRight: '15px',
              }}
              />
              <input
                type="file"
                id="inputFile"
                onChange={handleImageUpload}
                accept="image/*"
                multiple
                style={{
                  position: 'absolute',
                  left: '0',
                  top: '0',
                  width: '100%',
                  height: '100%',
                  zIndex: '1',
                  opacity: '0',
                }}
              />
              <h1>Enviar imagens</h1>
            </button>

            <button
              type="button"
              aria-label="Botão de adicionar vídeos"
              style={{
                width: '100%',
              }}
            >
              <VideocamIcon style={{
                color: theme.iconsColor,
                marginRight: '15px',
              }}
              />
              <input
                type="file"
                id="inputFile"
                onChange={handleVideoUpload}
                accept="video/*"
                multiple
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '40px',
                  zIndex: '1',
                  opacity: '0',
                }}
              />
              <h1>Enviar vídeos</h1>
            </button>
            <button
              type="button"
              aria-label="Botão de adicionar documentos"
              style={{
                width: '100%',
              }}
            >
              <InsertDriveFileIcon style={{
                color: theme.iconsColor,
                marginRight: '15px',
              }}
              />
              <input
                type="file"
                id="inputFile"
                onChange={handleDocumentsUpload}
                accept="*"
                multiple
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '40px',
                  zIndex: '1',
                  opacity: '0',
                }}
              />
              <h1>Enviar documentos</h1>
            </button>
          </PopOverMenu>

        </div>
      ) : (
        <div>
          {!isInternalChat && (
          <Tooltip
            arrow
            title="Transferir chamado"
          >
            <button
              type="button"
              aria-label="Tranferir chamado"
              className="transfer-ticket-button"
              onClick={handleOpenTransferModal}
            >
              <SyncAltIcon />
            </button>

            <CustomDialog
              open={openTransferModal}
              onClose={handleCloseTransferModal}
              message="Para qual departamento deseja transferir?"
              enableConfirmButton={selectedDepartment !== 'default'}
              actionName="Transferir"
              onConfirm={handleOnConfirmTransferTicket}
              confirmButtonColor={theme.secondaryColor}
            >
              <TransferTicketSection />
            </CustomDialog>
          </Tooltip>
          )}

          <PopOverMenu
            tooltipTitle="Transferir chamado"
            icon={<MoreVertIcon style={{ color: theme.iconsColor }} />}
          >

            <div>

              <button
                type="button"
                aria-label={chatOptions.ariaLabel}
                onClick={handleOpenTicketModal}
                style={{
                  borderBottom: '1px solid #CCC',
                  width: '100%',
                }}
              >
                {chatOptions.icon}

                <h1>{chatOptions.title}</h1>
              </button>

              <CustomDialog
                contactName={isInternalChat ? '' : `${contactName}?`}
                open={openTicketModal}
                onClose={handleCloseTicketModal}
                onConfirm={() => {
                  if (isInternalChat) {
                    if (currentChat?.arquivado === 1) {
                      return handleUnarchiveInternalChat();
                    }
                    return handleArchiveInternalChat();
                  }
                  handleCloseTicket();
                }}
                message={chatOptions.dialogMessage}
                actionName={chatOptions.actionName}
                confirmButtonColor={chatOptions.confirmButtonColor}
              />

              <button
                type="button"
                aria-label="Botão de adicionar imagens"
                style={{
                  borderBottom: '1px solid #CCC',
                  width: '100%',
                }}
              >
                <ImageIcon style={{
                  color: theme.iconsColor,
                  marginRight: '15px',
                }}
                />
                <input
                  type="file"
                  id="inputFile"
                  onChange={handleImageUpload}
                  accept="image/*"
                  multiple
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '40px',
                    zIndex: '1',
                    opacity: '0',
                  }}
                />
                <h1>Enviar imagens</h1>
              </button>

              <button
                type="button"
                aria-label="Botão de adicionar vídeos"
                style={{

                  borderBottom: '1px solid #CCC',
                  width: '100%',
                }}
              >
                <VideocamIcon style={{
                  color: theme.iconsColor,
                  marginRight: '15px',
                }}
                />
                <input
                  type="file"
                  id="inputFile"
                  onChange={handleVideoUpload}
                  accept="video/*"
                  multiple
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '40px',
                    zIndex: '1',
                    opacity: '0',
                  }}
                />
                <h1>Enviar vídeos</h1>
              </button>
              <button
                type="button"
                aria-label="Botão de adicionar documentos"
                style={{
                  width: '100%',
                }}
              >
                <InsertDriveFileIcon style={{
                  color: theme.iconsColor,
                  marginRight: '15px',
                }}
                />
                <input
                  type="file"
                  id="inputFile"
                  onChange={handleDocumentsUpload}
                  accept="*"
                  multiple
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '40px',
                    zIndex: '1',
                    opacity: '0',
                  }}
                />
                <h1>Enviar documentos</h1>
              </button>

            </div>
          </PopOverMenu>
        </div>
      )}
      {!isInternalChat && (
        <ScheduleMessageDialog
          openScheduleMessage={openScheduleMessage}
          handleCloseScheduleMessage={handleCloseScheduleMessage}
          sender={currentChat?.sender}
          fromMsg={currentChat?.fromMsg}
        />
      )}

    </Container>
  );
}

ChatHeader.propTypes = {
  contactName: PropTypes.string.isRequired,
  isOnline: PropTypes.bool.isRequired,
  isInternalChat: PropTypes.number,
  ipbxNumber: PropTypes.string,
  attendantName: PropTypes.string,
  leadOrigin: PropTypes.string,
};
