import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #EDEDED;

  .session-conflict-modal {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
    max-width: 450px;

    h2 {
      color: #014F6D;
      margin-bottom: 20px;
    }

    p {
      color: #333;
      font-weight: 500;
      margin: 30px 0 20px 0;
      text-align: center;
    }

    button {
      background-color: #014F6D;
      color: #fff;
      border: none;
      border-radius: 5px;
      padding: 10px 15px;
      cursor: pointer;
      margin: 10px 5px;
      width: 100%;
      max-width: 300px;
      font-size: 16px;
      transition: 0.2s ease-in;
    }

    button:hover {
      filter: brightness(1.2);
    }
  }
`;
