import PropTypes from 'prop-types';
import moment from 'moment';
import he from 'he';
import Tooltip from '@mui/material/Tooltip';
import ModalImage from 'react-modal-image';
import ErrorIcon from '@mui/icons-material/Error';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import DownloadIcon from '@mui/icons-material/Download';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { useTheme } from 'styled-components';
import { Container } from './styles';
import { ContactCard } from '../ChatMessage/styles';
import {
  formatMessageBody, formatContactCardBody, formatPhoneNumber, getSenderName,
} from '../../utils/services';

function QuotedMessage({
  quotedMessage,
  currentChat,
  onClose = null,
  isEditing = false,
  isReplying = false,
}) {
  if (!quotedMessage) return null;

  const theme = useTheme();

  return (
    <Container className="quoted-message-box">
      <div className="left-bar" />
      <div className="quoted-message-wrapper">
        <div className="quoted-message-content">
          {quotedMessage.error && quotedMessage.statusCode === 404 ? (
            <p className="error-quoted-message-text">
              <ErrorIcon />
              <em>Mensagem não encontrada na base de dados</em>
            </p>
          ) : (
            <>
              <span className="sender-name">
                {quotedMessage.fromMe === 1 ? 'Você' : getSenderName(quotedMessage.fromMe, currentChat)}
                {' '}
                {currentChat?.nome && quotedMessage.fromMe === 0
                  ? formatPhoneNumber(currentChat.fromMsg)
                  : ''}

                {isEditing && (
                  <>
                    <EditOutlinedIcon />
                    Editar mensagem
                  </>
                )}
              </span>

              <div className="media-quoted-message-box">
                {quotedMessage.mediaType === 'contactMessage' ? (
                  <ContactCard>
                    {formatContactCardBody(he.decode(quotedMessage.body), quotedMessage.mediaType)}
                  </ContactCard>
                ) : (
                  <>
                    <span className="quoted-message-text">
                      {formatMessageBody(
                        he.decode(quotedMessage.body),
                        quotedMessage.isDeleted,
                        quotedMessage?.isInternalChat,
                      )}
                    </span>

                    {quotedMessage.isDeleted === 1 && (
                    <span className="message-deleted-text">
                      <BlockIcon color="#919191" fontSize="12px" />
                      Mensagem excluída pelo usuário
                    </span>
                    )}
                  </>
                )}

                {quotedMessage.mediaType?.startsWith('image') && (
                <div
                  className="quoted-message-media-div"
                  style={{
                    width: quotedMessage?.mediaType === 'image/webp' ? '150px' : '300px',
                  }}
                >
                  <ModalImage
                    className="quoted-media"
                    small={quotedMessage?.mediaUrl}
                    large={quotedMessage?.mediaUrl}
                    alt="Imagem enviada pelo usuário"
                  />
                </div>
                )}
                {quotedMessage.mediaType?.startsWith('audio') && (
                <div style={{ maxWidth: '500px' }}>
                  <audio controls>
                    <source src={quotedMessage?.mediaUrl} type="audio/webm" />
                    <track kind="captions" src="" />
                  </audio>
                </div>
                )}
                {quotedMessage.mediaType?.startsWith('video') && (
                <div className="quoted-message-media-div">
                  <video
                    className="quoted-media"
                    src={quotedMessage?.mediaUrl}
                    controls
                    style={{
                      width: '100%',
                    }}
                  >
                    <track kind="captions" srcLang="pt" label="Portuguese captions" />
                  </video>
                </div>
                )}

                {quotedMessage.mediaType?.startsWith('application') && (
                <div style={{ position: 'relative', height: 'auto' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: 'auto',
                    }}
                  >
                    <Tooltip arrow title="Baixar documento">
                      <a
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '5px',
                          padding: '8px 12px',
                          borderRadius: '4px',
                          backgroundColor: '#014F6D',
                          color: '#F5F5F5',
                          textDecoration: 'none',
                        }}
                        href={quotedMessage?.mediaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Baixar documento"
                        download
                      >
                        <DownloadIcon
                          style={{
                            color: '#F5F5F5',
                            fontSize: '30px',
                          }}
                        />
                        Baixar documento
                      </a>
                    </Tooltip>
                  </div>
                </div>
                )}

                {(quotedMessage.mediaType?.startsWith('text/csv')
                || quotedMessage.mediaType?.startsWith('text/plain')) && (
                <div className="quoted-message-media-div" style={{ position: 'relative' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <Tooltip arrow title="Baixar documento">
                      <a
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          marginTop: '5px',
                          padding: '8px 12px',
                          borderRadius: '4px',
                          backgroundColor: '#014F6D',
                          color: '#F5F5F5',
                          textDecoration: 'none',
                        }}
                        href={quotedMessage?.mediaUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Baixar documento"
                        download
                      >
                        <DownloadIcon
                          style={{
                            color: '#F5F5F5',
                            fontSize: '30px',
                          }}
                        />
                        Baixar documento
                      </a>
                    </Tooltip>
                  </div>
                </div>
                )}

                {quotedMessage.fromMe !== 2 && (
                <span className="time-and-confirmation-span">
                  {moment(quotedMessage.createdAt).calendar()}
                  <span style={{ display: 'flex' }}>
                    {quotedMessage.fromMe === 1 && (
                      <CheckIcon
                        style={{
                          color: quotedMessage.isReaded
                            ? '#53BDEB'
                            : theme.unseenMessageColor,
                          fontSize: '20px',
                        }}
                      />
                    )}
                    {quotedMessage.fromMe === 1 && quotedMessage.isReceived && (
                      <CheckIcon
                        style={{
                          color: quotedMessage.isReaded
                            ? '#53BDEB'
                            : theme.unseenMessageColor,
                          fontSize: '20px',
                          marginLeft: '-15px',
                        }}
                      />
                    )}
                  </span>
                </span>
                )}

                {quotedMessage.reacted && (
                <Tooltip arrow title={he.decode(quotedMessage.reacted)}>
                  <div
                    style={{
                      width: '30px',
                      height: '30px',
                    }}
                    className="message-reacted-div"
                  >
                    {he.decode(quotedMessage.reacted)}
                  </div>
                </Tooltip>
                )}
              </div>
            </>
          )}
        </div>
        {currentChat.is_internal_chat === 1 && (isEditing || isReplying) && (
          <button
            aria-label="Cancelar"
            type="button"
            onClick={onClose}
          >
            <CloseIcon style={{ color: theme.iconsColor }} />
          </button>
        )}

      </div>
    </Container>
  );
}

export default QuotedMessage;

QuotedMessage.propTypes = {
  quotedMessage: PropTypes.shape({
    body: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    isReceived: PropTypes.bool.isRequired,
    isReaded: PropTypes.bool.isRequired,
    fromMe: PropTypes.number.isRequired,
    isInternalChat: PropTypes.bool.isRequired,
    openMessageEdit: PropTypes.func.isRequired,
    createdAt: PropTypes.string.isRequired,
    isEdited: PropTypes.bool,
    error: PropTypes.string,
    statusCode: PropTypes.number,
    idMsg: PropTypes.string,
    quotedMsgId: PropTypes.string,
    reacted: PropTypes.string,
    isDeleted: PropTypes.number,
    mediaUrl: PropTypes.string,
    mediaType: PropTypes.string,
    videoThumb: PropTypes.string,
  }).isRequired,
  currentChat: PropTypes.shape({
    nome: PropTypes.string.isRequired,
    fromMsg: PropTypes.string.isRequired,
    is_internal_chat: PropTypes.number,
  }).isRequired,
  onClose: PropTypes.func,
  isEditing: PropTypes.bool,
  isReplying: PropTypes.bool,
};
