import styled from 'styled-components';
import { Popover } from '@mui/material';

export const Container = styled.div`
background-color: ${({ theme }) => theme.backgroundColor};
border-right: 1px solid #DDD;
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
max-width: 480px;
overflow-x: hidden;
position: relative;

header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 5px 10px 0;
  background-color: ${({ theme }) => theme.buttonBackground};
  border-bottom: 1px solid #DDD;

  h1 {
    max-width: 50%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .sidebar-header-icons {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .show-kanban-button {
    padding: 8px;
    border-radius: 50%;
  }
}

header img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin: 0 0 0 15px;
}

header h1 {
  width: 50%;
  font-size: 20px;
}

header button {
  background-color: transparent;
  margin-right: 15px;
}

main {
  overflow-y: auto;

  .internal-chat-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h3 {
      color: ${({ theme }) => theme.secondaryFontColor};
    }

    .archived-chats-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border-bottom: 1px solid ${({ theme }) => theme.borderColor};
      padding: 10px;
      font-size: 16px;
      background-color: ${({ theme }) => theme.buttonBackground};
      transition: 0.2s ease-out;

      &:hover {
        background-color: ${({ theme }) => theme.hoverButtonBackground};
      }

      svg {
        font-size: 22px;
        color: ${({ theme }) => theme.iconsColor};
      }
    }

    .new-chat-button {
      border-radius: 50%;
      background-color: ${({ theme }) => theme.secondaryColor};
      padding: 15px;
      color: ${({ theme }) => theme.buttonBackground};
      transition: 0.2s;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);

      .MuiTooltip-tooltip {
        font-size: 25px;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

&::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

&::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0,0,0,0.2);
}
}
`;

export const StyledPopover = styled(Popover)`
  width: 100%;
  padding: 15px;

  h4 {
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
  }

  .chat-mode-toggle {
    margin-bottom: 15px;
    label {
      font-size: 18px;
      cursor: pointer;

      input {
        margin-right: 10px;
      }
    }
  }

  .user-list {
    max-height: 300px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 10px;
      height: 6px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(0,0,0,0.2);
    }
  }

  .select-user-button {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 5px;
    width: 100%;
    font-size: 16px;
    transition: 0.2s ease;
    border: none;
    border-radius: 5px;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const UserCheckbox = styled.div`
  margin-bottom: 10px;

  label {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: ${({ theme }) => theme.primaryText};

    input {
      margin-right: 10px;
    }
  }
`;

export const StyledButton = styled.button`
  display: block;
  margin: 15px auto 0;
  padding: 10px 15px;
  background-color: ${({ theme }) => theme.secondaryColor};
  color: ${({ theme }) => theme.buttonBackground};
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  transition: 0.2s ease;

  &:hover {
    filter: brightness(0.8);
  }
`;

export const GroupDetails = styled.div`
  margin-bottom: 10px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;

  }

  input,
  textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #CCC;
    border-radius: 5px;
    font-size: 1rem;
  }

  textarea {
    resize: none;
    height: 80px;
  }
`;
