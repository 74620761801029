import React, { useContext, useState, useCallback } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from 'styled-components';
import { Container, StyledButton } from './styles';
import { AuthContext } from '../../context/AuthContext';
import { ChatContext } from '../../context/ChatContext';
import { baseUrl, patchRequest } from '../../utils/services';

export default function EditProfile() {
  const {
    user,
    needsPasswordReset,
    setNeedsPasswordReset,
    passwordChangeMessage,
    setPasswordChangeMessage,
  } = useContext(AuthContext);

  const theme = useTheme();

  const { setIsEditingProfile } = useContext(ChatContext);

  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [isPasswordChangeError, setIsPasswordChangeError] = useState(false);

  const handleCancelEdit = () => {
    setIsEditingProfile(false);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswords({ ...passwords, [name]: value });
  };

  const handleSubmitPasswordChange = async () => {
    try {
      const { currentPassword, newPassword, confirmPassword } = passwords;

      if (newPassword !== confirmPassword) {
        setIsPasswordChangeError(true);
        setPasswordChangeMessage('As senhas novas não coincidem.');
        return;
      }

      patchRequest(`${baseUrl}/users/update-password`, JSON.stringify({ userId: user.id, currentPassword, newPassword }));

      setIsPasswordChangeError(false);
      setPasswordChangeMessage('Senha alterada com sucesso.');
    } catch (error) {
      console.error('error changing password: ', error);
      setIsPasswordChangeError(true);
      setPasswordChangeMessage(
        error.response?.data || 'Erro ao alterar senha. Tente novamente.',
      );
    } finally {
      setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
      setNeedsPasswordReset(false);
    }
  };

  return (
    <div style={{ borderRight: '1px solid #DDD', width: '480px' }}>
      {!needsPasswordReset && (
      <StyledButton
        type="button"
        aria-label="Fechar janela de edição de perfil"
        onClick={handleCancelEdit}
      >
        <CloseIcon style={{ color: theme.iconsColor }} />
      </StyledButton>
      )}

      <Container>

        <div className="update-password-div">
          <input
            type="password"
            name="currentPassword"
            placeholder="Senha atual"
            value={passwords.currentPassword}
            onChange={handlePasswordChange}
          />
          <input
            type="password"
            name="newPassword"
            placeholder="Nova senha"
            value={passwords.newPassword}
            onChange={handlePasswordChange}
          />
          <input
            type="password"
            name="confirmPassword"
            placeholder="Confirmar nova senha"
            value={passwords.confirmPassword}
            onChange={handlePasswordChange}
          />
          <button
            type="button"
            onClick={handleSubmitPasswordChange}
            aria-label="Alterar senha"
            disabled={
              !passwords.currentPassword
              || !passwords.newPassword
              || !passwords.confirmPassword
            }
          >
            Alterar senha
          </button>
        </div>

        {passwordChangeMessage !== '' && (
          <Snackbar
            autoHideDuration={5000}
            open
            onClose={() => setPasswordChangeMessage('')}
          >
            <Alert
              style={{ marginTop: '20px' }}
              variant="filled"
              severity={isPasswordChangeError ? 'error' : 'success'}
              onClose={() => setPasswordChangeMessage('')}
            >
              {passwordChangeMessage}
            </Alert>
          </Snackbar>
        )}

      </Container>
    </div>
  );
}
